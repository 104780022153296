<template>
  <v-btn-toggle
    class="improved-btn-toggle"
    v-model="selectedButtons"
    multiple
  >
    <v-btn
      v-for="(item, index) in items"
      :key="item"
      @click="handleItemClick($event, item)"
      :value="item"
    >
      <document-picker-list-item-ref :item="{id: buildId(item), type: documentType}" />
      <span v-if="additionalText && additionalText[index]">{{additionalText[index]}}</span>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  components: {
    DocumentPickerListItemRef: () => import('@/components/document-picker-list-item-ref')
  },
  data () {
    return {
      excludeMode: false,
      innerSelection: []
    }
  },
  computed: {
    selectedButtons: {
      get () {
        return this.selection ?? this.innerSelection
      },
      set () { }
    }
  },
  methods: {
    buildId (id) {
      return this.tuplePrefixId ? [this.tuplePrefixId, id] : id
    },
    handleItemClick (e, id) {
      if (this.innerSelection.length && e.shiftKey) {
        this.innerSelection = this.innerSelection.some(i => this.lodash.isEqual(i, id)) ? this.lodash.difference(this.innerSelection, [id]) : this.innerSelection.concat(id)
      } else {
        if (!e.ctrlKey) {
          this.innerSelection = this.innerSelection.length === 1 && this.lodash.isEqual(this.innerSelection[0], id) ? [] : [id]
        } else {
          this.innerSelection = this.innerSelection.length === this.items.length - 1 && !this.innerSelection.some(i => this.lodash.isEqual(i, id)) ? [] : this.lodash.difference(this.items, [id])
        }
      }

      this.$emit('update:selection', this.innerSelection)
    }
  },
  props: {
    additionalText: {
      type: Array
    },
    documentType: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    selection: Array,
    tuplePrefixId: String
  }
}
</script>

<style lang="stylus">
  .improved-btn-toggle .v-btn__content
    display flex
    flex-direction column
    align-items flex-end
</style>
